import React, { useEffect, useState } from 'react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import serializeData from "./serialize-data.utils";
import { LogEntry, SummaryData } from './types';
import LogEntriesTable from './LogEntriesTable';
import SummaryDataTable from './SummaryDataTable';

const App: React.FC = () => {
  const [logEntries, setLogEntries] = useState<LogEntry[]>([]);
  const [summaryData, setSummaryData] = useState<SummaryData[]>([]);
  const [activeTab, setActiveTab] = useState<number>(1);
  const [from, setFrom] = useState<string | null>(null)
  const [to, setTo] = useState<string | null>(null)
  const [shouldBeUpdated, setShouldBeUpdated] = useState<boolean>(false)

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (shouldBeUpdated) {
    fetchData();
    setShouldBeUpdated(false)
    }

  }, [shouldBeUpdated]);

  const fetchData = async () => {
    try {
      const response = await fetch('/api/parsed_logs');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      const { logEntries, summaryData } = serializeData(data, to, from);


      setLogEntries(logEntries);
      setSummaryData(summaryData);

    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  return (
      <>
        <div style={{ height: '40px', display: 'flex', padding: '10px 10px' }}>
          <button
              style={{ cursor: 'pointer', background: `${activeTab === 1 ? '#99d4ff' : '#fff'}`, width: '70px', outline: 'none', borderRadius: '5px 0 0 5px', borderRight: 'none' }}
              onClick={() => setActiveTab(1)}
          >
            Data
          </button>
          <button
              style={{ cursor: 'pointer', background: `${activeTab === 2 ? '#99d4ff' : '#fff'}`, width: '70px', outline: 'none', borderRadius: '0 5px 5px 0', borderLeft: 'none' }}
              onClick={() => setActiveTab(2)}
          >
            Summary
          </button>
          <div style={{height: '100%', display: 'flex', alignItems: 'center', gap: '5px', padding: '0 10px'}}>

            <label htmlFor="From">From</label>
          <input name='from' type="date" onChange={(e) => {
setFrom(e.target.value)
          }}/>
            <label htmlFor="to">To</label>
          <input name='to' type="date" onChange={(e) => {
            setTo(e.target.value)
          }}/>
            <button onClick={()=> {
              setShouldBeUpdated(true)
            }}>Load</button>
          </div>
        </div>
        <div className="ag-theme-alpine" style={{ height: 'calc(100vh - 60px)', width: '100%' }}>
          {activeTab === 1 ? (
              <LogEntriesTable logEntries={logEntries} />
          ) : (
              <SummaryDataTable summaryData={summaryData} />
          )}
        </div>
      </>
  );
};

export default App;