import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { LogEntry } from './types';

interface LogEntriesTableProps {
    logEntries: LogEntry[];
}

const LogEntriesTable: React.FC<LogEntriesTableProps> = ({ logEntries }) => {
    const columnDefs = [
        { headerName: 'IP', field: 'IP', filter: true } as const,
        { headerName: 'Time', field: 'time' } as const,
        { headerName: 'URL', field: 'url', filter: true } as const,
        { headerName: 'Status', field: 'status' } as const,
        { headerName: 'Bytes Sent', field: 'bytesSent' } as const,
        { headerName: 'User Agent', field: 'userAgent' } as const,
        { headerName: 'Request Time', field: 'requestTime', filter: "agNumberColumnFilter" } as const,
        { headerName: 'Response Time', field: 'responseTime', filter: "agNumberColumnFilter" } as const,
    ];

    return (
        <AgGridReact
            rowData={logEntries}
            columnDefs={columnDefs}
            defaultColDef={{ resizable: true }}
            pagination
            paginationPageSize={500}
            paginationPageSizeSelector={[200, 500, 1000]}
            enableCellTextSelection
        />
    );
};

export default LogEntriesTable;