//@ts-ignore
const getWeightedMean = (data) => {

// Group data into ranges
    //@ts-ignore
    const group_0_2 = data.filter(x => 0 <= x && x < 2);
    //@ts-ignore
    const group_2_5 = data.filter(x => 2 <= x && x < 5);
    //@ts-ignore
    const group_5_20 = data.filter(x => 5 <= x && x < 20);
    //@ts-ignore
    const group_20_inf = data.filter(x => x >= 20);

// Calculate frequencies
    const freq_0_2 = group_0_2.length;
    const freq_2_5 = group_2_5.length;
    const freq_5_20 = group_5_20.length;
    const freq_20_inf = group_20_inf.length;

// Define midpoints
    const midpoint_0_2 = 1;
    const midpoint_2_5 = 3.5;
    const midpoint_5_20 = 12.5;

// Calculate weighted sum
    const weighted_sum = (
        midpoint_0_2 * freq_0_2 +
        midpoint_2_5 * freq_2_5 +
        midpoint_5_20 * freq_5_20 +
        //@ts-ignore
        group_20_inf.reduce((sum, value) => sum + value, 0)
    );

// Calculate total frequency
    const total_frequency = freq_0_2 + freq_2_5 + freq_5_20 + freq_20_inf;

// Calculate weighted mean
    const weighted_mean = weighted_sum / total_frequency;

    return weighted_mean
}

export default getWeightedMean
