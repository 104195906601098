
//@ts-ignore
import getWeightedMean from "./get-weighted-mean.utils";


//@ts-ignore
function weightedArithmeticMean(values) {
    // Check if the input array is empty
    if (values.length === 0) return 0

    // Calculate the sum of weighted values and the sum of weights
    let weightedSum = 0
    let weightSum = 0

//@ts-ignore
    values.forEach((value) => {
        weightedSum += value * value
        weightSum += value
    })

    // Calculate the weighted arithmetic mean
    const weightedMean = weightedSum / weightSum

    return weightedMean
}

// @ts-ignore
const serealizeCalc = (data) => {
    return Object.keys(data).map((key)=> {
        const times = data[key] as number[]

        const url = key
        const maxTime = Math.max(...times)
        const minTime = Math.min(...times)
        const weightedArithmetic = parseFloat(weightedArithmeticMean(times).toFixed(3))
        const count = times.length
        const weightedMean = parseFloat(getWeightedMean(times).toFixed(3))

        return {url, maxTime, minTime, weightedArithmetic, count, weightedMean}
    })
}

export default serealizeCalc