import type { LogEntry, SummaryData } from './types';
import { format } from 'date-fns';
import serializeSummary from "./serialize-summary.utils";

const parseRequestLine = (requestLine: string): string | null => {
    const pattern = /^(GET|POST|PUT|DELETE)\s+([^?\s]+)/;
    const match = requestLine.match(pattern);
    return match ? match[2] : null;
};

const serializeData = (data: LogEntry[], to: string | null, from: string| null): { logEntries: LogEntry[], summaryData: SummaryData[] } => {
    let urlRequestTimes: { [key: string]: number[] } = {};

    const logEntries = data.filter((entry)=> {
        const createdAt = new Date(entry.time).getTime()

        const getToWithOffset = (to: string) => {
            const date = new Date(to)

            date.setHours(23)
            date.setMinutes(59)

            return date.getTime()
        }

        const fromatedTo = to ? getToWithOffset(to) : null
        const fromatedFrom = from ? new Date(from).getTime() : null

        if (fromatedTo && fromatedFrom) {
            return  createdAt >= fromatedFrom && createdAt <= fromatedTo
        }

        if (fromatedTo) {
            return  createdAt <= fromatedTo
        }

        if (fromatedFrom) {
            return  createdAt >= fromatedFrom
        }

        return true
    }).map((entry) => {
        const requestTime = parseFloat(entry.request_time.replace('.', '')) / 1000;
        const responseTime = parseFloat(entry.response_time.replace('.', '')) / 1000;
        const url = (parseRequestLine(entry.url) || '').replace(/\/(?!100000\b)[0-9]{2,5}/, '/{ID}');

        if (urlRequestTimes[url]) {
            urlRequestTimes[url].push(requestTime);
        } else {
            urlRequestTimes[url] = [requestTime];
        }

        return {
            ...entry,
            time: format(new Date(entry.time), 'dd.MM.yyyy HH:mm'),
            url,
            requestTime,
            responseTime,
            userAgent: entry.user_agent,
            bytesSent: entry.bytes_sent
        };
    });

    const summaryData = serializeSummary(urlRequestTimes);

    //@ts-ignore
    return { logEntries, summaryData };
};

export default serializeData;