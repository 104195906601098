import React from 'react';
import { AgGridReact } from 'ag-grid-react';
import { SummaryData } from './types';

interface SummaryDataTableProps {
    summaryData: SummaryData[];
}

const SummaryDataTable: React.FC<SummaryDataTableProps> = ({ summaryData }) => {
    const columnDefs = [
        { headerName: 'URL', field: 'url' } as const,
        { headerName: 'Count', field: 'count' } as const,
        { headerName: 'Min Time', field: 'minTime' } as const,
        { headerName: 'Max Time', field: 'maxTime' } as const,
        { headerName: 'Weighted Arithmetic by (value))', field: 'weightedArithmetic' } as const,
        { headerName: 'Weighted Arithmetic by (Hz)', field: 'weightedMean' } as const,
    ];

    return (
        <AgGridReact
            rowData={summaryData}
            columnDefs={columnDefs}
            defaultColDef={{ resizable: true }}
            enableCellTextSelection
            pagination={false}
        />
    );
};

export default SummaryDataTable;